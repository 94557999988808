import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { useContext, useEffect } from 'react';
import { AnyExtension, EditorContent, EditorEvents, useEditor } from '@tiptap/react';
import { extensionsSet } from 'components/editor/GridDndEditor/Block/TiptapEditor/hooks/useTextEditor';
import { ActiveEditorContext } from 'components/editor/GridDndEditor/Block/TiptapEditor/ActiveEditorContext';
import { TextEditorProps } from 'components/editor/GridDndEditor/Block/TiptapEditor/types';
import Placeholder from '@tiptap/extension-placeholder';
import { getCustomMention } from 'components/editor/GridDndEditor/Block/TiptapEditor/extensions/CustomMention';
import { TableRowTypes } from '../MuiDataGrid';
import { getSanitizedDataForTableFooter } from '../../utils/getSanitizedDataForTableFooter';
import { useSectionData } from 'components/editor/Sections/useSectionContext';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { useTableManipulation } from 'components/editor/GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';

const TiptapEditor = ({ placeholder, value, mentions, userPathname, field, id, hasFocus, ...editorOptions }: TextEditorProps) => {
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData } = useTableManipulation();
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const blockId = selectedBlockIdByWrapper as string;
  const { setActiveEditor } = useContext(ActiveEditorContext);
  const editor = useEditor({
    content: value,
    extensions: [
      Placeholder.configure({
        placeholder,
      }),
      getCustomMention({ pathname: userPathname, mentions }),
      ...extensionsSet,
    ] as AnyExtension[],

    onUpdate: async ({ editor }: EditorEvents['update']) => {
      const html = editor.getHTML();
      const tableData = getTableData(blockId, sectionId);
      if (tableData) {
        const updatedRows = tableData?.rows.map((row) => {
          if (row.id === id && row.rowType !== TableRowTypes.FOOTER) {
            return { ...row, [field]: html };
          } else if (row.id === id && row.rowType === TableRowTypes.FOOTER) {
            const convertedRowToFooterData = getSanitizedDataForTableFooter(row, tableData.columns, html);
            return { ...convertedRowToFooterData, rowType: TableRowTypes.FOOTER };
          } else {
            return { ...row };
          }
        });
        await blockContentChangedHandler(blockId, sectionId, { ...tableData, rows: updatedRows });
      }
    },
    ...editorOptions,
  });
  useEffect(() => {
    if (editor && hasFocus) {
      setActiveEditor(editor);
    }
  }, [editor, setActiveEditor, hasFocus]);

  return (
    <div className={`editable-cell-wrapper editable-cell-${id}`}>
      <EditorContent editor={editor} />
    </div>
  );
};

export const DataGridCustomAutoGrowingInput = (props: GridRenderEditCellParams) => {
  return (
    <TiptapEditor
      inputClassName={`flexColumn stretchSelf flex1 tracking-block-${props.id}`}
      placeholder="Enter your content here"
      editable={true}
      value={props.value}
      api={props.api}
      field={props.field}
      id={props.id}
      hasFocus={props.hasFocus}
    />
  );
};
