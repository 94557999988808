import React, { createContext, useState, ReactNode } from 'react';
import { Editor } from '@tiptap/react';

interface ActiveEditorContextProps {
  activeEditor: Editor | null;
  setActiveEditor: (editor: Editor | null) => void;
}

interface ActiveEditorProviderProps {
  children: ReactNode; // Define children prop
}

export const ActiveEditorContext = createContext<ActiveEditorContextProps>({} as ActiveEditorContextProps);

export const ActiveEditorProvider: React.FC<ActiveEditorProviderProps> = ({ children }) => {
  const [activeEditor, setActiveEditor] = useState<Editor | null>(null);
  const context: ActiveEditorContextProps = { activeEditor, setActiveEditor };

  return <ActiveEditorContext.Provider value={context}>{children}</ActiveEditorContext.Provider>;
};
