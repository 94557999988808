/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { Editor } from '@tiptap/react';
import { Menu, MenuItem, Fade, Button } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import ChevronDown from '../icons/ChevronDown';
import Icon from '../icons/Icon';
import { ILabels } from '../types';
import { getBorderColor } from '../utils/app.utils';

const isActive = (editor: Editor) => {
  return (
    editor.isActive('fontFamily', { fontFamily: 'Arial' }) ||
    editor.isActive('fontFamily', { fontFamily: 'Helvetica' }) ||
    editor.isActive('fontFamily', { fontFamily: 'Times New Romans' }) ||
    editor.isActive('fontFamily', { fontFamily: 'Comic Sans MS' }) ||
    editor.isActive('fontFamily', { fontFamily: 'Inter' })
  );
};

const options: Record<string, string>[] = [
  { fontFamily: 'Arial' },
  { fontFamily: 'Helvetica' },
  { fontFamily: 'Times New Romans' },
  { fontFamily: 'Comic Sans MS' },
  { fontFamily: 'Inter' },
];

const classes = {
  fontFamily: (split: boolean) => (theme: Theme) =>
    css({
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderRight: split ? `1px solid ${getBorderColor(theme)}` : 'none',
    }),
  button: (isActive: boolean) => (theme: Theme) =>
    css({
      backgroundColor: 'transparent',
      fontWeight: 500,
      paddingLeft: 8,
      paddingRight: 8,
      textTransform: 'capitalize',
      border: isActive ? '0px solid gray !important' : 'none !important',
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      fontSize: 14,
      lineHeight: 1,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    }),
  menuItem: (isActive: boolean, fontFamily: string) => (theme: Theme) =>
    css({
      backgroundColor: isActive ? theme.palette.grey[100] : 'transparent',
      fontFamily,
    }),
};

type Props = {
  editor: Editor;
  fontFamilyLabels?: ILabels['fontFamilies'];
  split?: boolean;
};

const FontFamily = ({ editor, fontFamilyLabels, split = false }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState('Arial');

  const selectedLabel = useMemo(() => {
    const fontOption = options.find((option) => option.fontFamily === selected);

    if (fontOption) {
      return fontOption.fontFamily;
    }

    return 'Normal text';
  }, [selected, fontFamilyLabels]);

  const handleOpenHeadingMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleSelectFontFamily = (fontFamily: string) => {
    editor.chain().focus().setFontFamily(`${fontFamily}`).run();
    setSelected(fontFamily);
    handleClose();
  };

  const handleSelectNormalText = () => {
    editor.chain().focus().unsetFontFamily().run();
    setSelected('Arial');
    handleClose();
  };

  return (
    // eslint-disable-next-line react/no-unknown-property
    <span css={classes.fontFamily(split)}>
      <Button
        type="button"
        onClick={handleOpenHeadingMenu}
        sx={classes.button(isActive(editor))}
        className="flexRow center"
        variant="text"
        color="inherit"
      >
        <span style={{ marginRight: 8 }}>{selectedLabel}</span>
        <Icon>
          <ChevronDown />
        </Icon>
      </Button>
      <Menu
        id="select-fontFamily-menu"
        MenuListProps={{
          'aria-labelledby': 'select-fontFamily-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleSelectNormalText}>{fontFamilyLabels?.normalText || 'Normal text'}</MenuItem>
        {options.map(({ fontFamily }, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelectFontFamily(fontFamily)}
            sx={classes.menuItem(editor.isActive('fontFamily', { fontFamily: fontFamily }), fontFamily)}
          >
            {fontFamilyLabels?.[fontFamily] || 'Arial'}
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};

export default FontFamily;
