import { SvgIcon } from '@mui/material';

const Strike = () => {
  return (
    <SvgIcon>
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 7.25016H10.467C10.134 7.16062 9.79943 7.07686 9.4635 6.99891C7.3575 6.50091 6.1665 6.13641 6.1665 4.43166C6.1494 4.13738 6.19297 3.84272 6.29451 3.56597C6.39604 3.28923 6.55338 3.03631 6.75675 2.82291C7.3938 2.29904 8.19173 2.01038 9.0165 2.00541C11.139 1.95291 12.1178 2.67291 12.918 3.76792L14.1293 2.88291C13.5623 2.06868 12.7893 1.41955 11.8893 1.00185C10.9893 0.58414 9.99461 0.412828 9.00675 0.505415C7.79326 0.513162 6.62264 0.955073 5.70675 1.75116C5.35961 2.10329 5.08829 2.52279 4.90951 2.9838C4.73072 3.44481 4.64828 3.93756 4.66725 4.43166C4.63436 4.97233 4.73592 5.51272 4.96286 6.00455C5.1898 6.49638 5.53505 6.92433 5.96775 7.25016H0V8.75016H10.239C11.7143 9.17766 12.5963 9.73416 12.6188 11.2687C12.6431 11.5965 12.5982 11.9257 12.4868 12.2349C12.3754 12.5442 12.2001 12.8265 11.9722 13.0634C11.1786 13.689 10.1926 14.0201 9.18225 14.0002C8.42813 13.9783 7.68886 13.7853 7.02035 13.4356C6.35184 13.086 5.77159 12.5889 5.3235 11.9819L4.17375 12.9449C4.7563 13.7213 5.50792 14.3548 6.37167 14.7975C7.23541 15.2402 8.1886 15.4805 9.159 15.5002H9.234C10.6191 15.5161 11.9634 15.0317 13.02 14.1359C13.395 13.7578 13.6865 13.3053 13.8758 12.8075C14.065 12.3098 14.1478 11.7779 14.1188 11.2462C14.1467 10.3366 13.8398 9.4484 13.2562 8.75016H18V7.25016Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default Strike;
