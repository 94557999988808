import { SvgIcon } from '@mui/material';

const OrderedList = () => {
  return (
    <SvgIcon>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="17.5" viewBox="0 0 20 17.5">
        <path
          fill="currentColor"
          d="M18.75,7.5h-10a1.25,1.25,0,0,0,0,2.5h10a1.25,1.25,0,0,0,0-2.5Zm-10-3.75h10a1.25,1.25,0,0,0,0-2.5h-10a1.25,1.25,0,0,0,0,2.5Zm10,10h-10a1.25,1.25,0,0,0,0,2.5h10a1.25,1.25,0,0,0,0-2.5ZM1.56,7.5H4.69a1,1,0,0,0,.94-1,.91.91,0,0,0-.94-.94H4.06V.94A1,1,0,0,0,3.59.12a1,1,0,0,0-.94,0L1.4.86a.94.94,0,0,0,.79,1.69V5.62H1.56a.94.94,0,0,0,0,1.88Zm3.76,8.12H3.39l1.43-1.26a2.85,2.85,0,0,0,.31-4,2.78,2.78,0,0,0-2-1,2.9,2.9,0,0,0-2.11.69l-.49.43a.94.94,0,0,0,1.22,1.42l.49-.42a1,1,0,0,1,1.43.11A.94.94,0,0,1,3.58,13L.31,15.86A.94.94,0,0,0,.94,17.5H5.33a.93.93,0,0,0,.93-.9A1,1,0,0,0,5.32,15.62Z"
        />
      </svg>
    </SvgIcon>
  );
};

export default OrderedList;
