import { apiClient } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import {
  CreateDocumentApiResponse,
  DocumentProspect,
  DocumentRepository,
  DocumentStatus,
  SectionsCollectionByDocumentIdApiResponse,
  GetContentStyleByContentIdType,
  SigneeInterface,
  UploadDocumentImageResponse,
} from '../interfaces/DocumentRepository';
import { ProspectType } from 'services/repositories/interfaces/ProspectRepository';
import { generateProspectUid } from './ApiProspectsRepository';
import SnakeCaseKeys from 'snakecase-keys';
import { PromiseAllSettledResult } from 'interfaces/PromisedAllSettledResult';

interface CreateDocumentApiPayload {
  title?: string;
  price?: number;
  content_id: string;
}

interface UpdateDocumentApiPayload {
  title?: string;
  price?: number | null;
  assigned_user?: { id: number } | null;
  prospect?: {
    id: number;
    type: ProspectType;
    contact?: { id: number; name: string; email: string } | null;
  } | null;
}

type GetDocumentByIdApiResponse = {
  id: string;
  title: string;
  price: number;
  status: DocumentStatus;
  company: { id: number; name: string };
  is_locked: boolean;
  assigned_user?: { id: number; name: string } | null;
  prospect?: { id: number; name: string; type: ProspectType; contact?: null | { id: number; name: string; email: string } } | null;
  document_belongs_to_same_account: boolean;
  content_id?: string;
};

interface DocumentListApiResponse {
  documents: {
    id: string;
    title: string;
    company_name?: string;
    is_locked: boolean;
    assignee_user_first_name: string;
    assignee_user_last_name: string;
    prospect_first_name: string;
    prospect_last_name: string;
    editor_version: number;
    status: 'Draft' | 'Sent' | 'Won' | 'Lost' | 'Unsigned' | 'Approval' | 'Approved';
    created_at: string;
    deleted_at: string;
  }[];
}

interface SigneeApiObject {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  signee_type: 'USER' | 'PERSON';
}

interface SigneesApiResponse {
  signees: Array<SigneeApiObject>;
}

type DocumentLibraryImage = {
  link: string;
};

export type DocumentImageLibraryApiResponse = DocumentLibraryImage[];

export interface DocumentAPIUpdateResponse {
  status: number;
}

function Signee({ id, first_name, last_name, email, signee_type }: SigneeApiObject): SigneeInterface {
  return {
    uid: `${id}_${signee_type}`,
    id,
    firstName: first_name,
    lastName: last_name,
    email,
    signeeType: signee_type,
    fullName: [first_name, last_name].filter(Boolean).join(' '),
  };
}

export const apiDocumentRepository: DocumentRepository = {
  createDocument: async (payload) => {
    const data: CreateDocumentApiPayload = {
      title: payload.title,
      price: payload.price,
      content_id: payload.contentId,
    };
    const { document_id } = await apiClient.post<CreateDocumentApiResponse>(Endpoints.documents, data);
    return {
      documentId: document_id,
    };
  },
  updateDocument: async ({ documentId, ...payload }) => {
    const data = SnakeCaseKeys(payload);
    const resp = await apiClient.patch<DocumentAPIUpdateResponse, UpdateDocumentApiPayload>(Endpoints.documentDetail(documentId), data);
    const { status } = resp;

    return { status };
  },
  getContentStyleByContentId: async (contentId: string) => {
    return await apiClient.get<GetContentStyleByContentIdType>(Endpoints.previewContentStyleSettings(contentId));
  },
  getDocumentById: async (documentId, isPreview = false) => {
    const url = isPreview ? Endpoints.previewDetails(documentId) : Endpoints.documentDetail(documentId);
    const resp = await apiClient.get<GetDocumentByIdApiResponse>(url);
    const {
      id,
      title,
      price,
      status,
      is_locked,
      company,
      assigned_user = null,
      prospect: prospectFromApi,
      document_belongs_to_same_account: isDocumentBelongsToSameAccount,
      content_id,
    } = resp;

    let prospect: DocumentProspect | null = null;
    if (prospectFromApi != null) {
      const base = {
        id: prospectFromApi.id,
        name: prospectFromApi.name,
        uid: generateProspectUid(prospectFromApi),
      };
      if (prospectFromApi.type === ProspectType.Company) {
        prospect = {
          ...base,
          type: ProspectType.Company,
          contact: prospectFromApi.contact ?? null,
        };
      } else {
        prospect = {
          ...base,
          type: ProspectType.Person,
          contact: prospectFromApi.contact ?? null,
        };
      }
    }

    return {
      id,
      title,
      price,
      status,
      isLocked: is_locked,
      company,
      assignedUser: assigned_user,
      prospect,
      isDocumentBelongsToSameAccount,
      contentId: content_id,
    };
  },

  list: async () => {
    const response = await apiClient.get<DocumentListApiResponse>(Endpoints.documents);

    const documentList = response.documents;

    return {
      documents: documentList.map(
        ({
          id,
          title,
          company_name,
          is_locked,
          assignee_user_first_name,
          assignee_user_last_name,
          prospect_first_name,
          prospect_last_name,
          editor_version,
          status,
          created_at,
          deleted_at,
        }) => {
          return {
            id,
            title,
            companyName: company_name,
            isLocked: is_locked,
            assigneeUserFirstName: assignee_user_first_name,
            assigneeUserLastName: assignee_user_last_name,
            prospectFirstName: prospect_first_name,
            prospectLastName: prospect_last_name,
            editorVersion: editor_version,
            status,
            created_at,
            deleted_at,
          };
        }
      ),
    };
  },
  getSignees: async (accountId, params) => {
    const response = await apiClient.get<SigneesApiResponse>(Endpoints.accountSignees(accountId), { params: params });
    return {
      signees: response.signees.map((signee) => Signee(signee)),
    };
  },
  shareDocument: async ({ id, ...payload }) => {
    return apiClient.post<void>(Endpoints.documentShare(id), payload);
  },
  getSectionsByDocumentId: async (documentId) => {
    return await apiClient.get<SectionsCollectionByDocumentIdApiResponse>(Endpoints.sectionsBlocksForPreview(documentId));
  },
  getDocumentLibraryImages: async (documentId) => {
    return await apiClient.get<DocumentImageLibraryApiResponse>(Endpoints.documentImageLibraryImages(documentId));
  },
  uploadDocumentLibraryImages: async ({ documentId, images }): Promise<PromiseAllSettledResult<UploadDocumentImageResponse>[]> => {
    return (await Promise.allSettled(
      Array.from(images).map((image) => {
        const formData = new FormData();
        formData.append('image', image);
        return apiClient.post(Endpoints.documentUploadImage(documentId), formData);
      })
    )) as PromiseAllSettledResult<UploadDocumentImageResponse>[];
  },
};
