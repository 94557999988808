import { useContext } from 'react';
import Toolbar from '../GridDndEditor/Block/TiptapEditor/components/Toolbar';
import { ActiveEditorContext } from '../GridDndEditor/Block/TiptapEditor/ActiveEditorContext';

const TiptapMenuBar = () => {
  const { activeEditor: editor } = useContext(ActiveEditorContext);
  if (!editor) return null;

  return (
    <div>
      <Toolbar editor={editor} />
    </div>
  );
};

export default TiptapMenuBar;
