import { SvgIcon } from '@mui/material';

const Italic = () => {
  return (
    <SvgIcon>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.75 1.75V0.25H4V1.75H7.855L4.5775 12.25H0.25V13.75H10V12.25H6.145L9.4225 1.75H13.75Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  );
};

export default Italic;
