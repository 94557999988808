import { DataGridCustomAutoGrowingInput } from '../../../../../muiTheme/DataGridCustomAutoGrowingInput/DataGridCustomAutoGrowingInput';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { TableColumnType } from '../../../grid/reduxStore/saveHandlers';
import { AnyExtension, EditorEvents, EditorContent, useEditor } from '@tiptap/react';
import { extensionsSet } from 'components/editor/GridDndEditor/Block/TiptapEditor/hooks/useTextEditor';
import { getSanitizedDataForTableFooter } from '../../../../../utils/getSanitizedDataForTableFooter';
import { useContext, useEffect } from 'react';
import { useSectionData } from 'components/editor/Sections/useSectionContext';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from 'components/editor/GridDndEditor/Block/Table/useTableManipulation';
import { SelectionContext } from 'components/editor/GridDndEditor/SelectedBlockInfoProvider';
import { ActiveEditorContext } from 'components/editor/GridDndEditor/Block/TiptapEditor/ActiveEditorContext';

const RenderEditCell = (params) => {
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData } = useTableManipulation();
  const { setActiveEditor } = useContext(ActiveEditorContext);
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const blockId = selectedBlockIdByWrapper as string;
  const tableData = getTableData(blockId, sectionId);
  const { id, field, hasFocus } = params;
  const editor = useEditor({
    content: params.value ?? '',
    extensions: [...extensionsSet] as AnyExtension[],

    onUpdate: async ({ editor }: EditorEvents['update']) => {
      const html = editor.getHTML();
      if (tableData) {
        const updatedRows = tableData?.rows.map((row) => {
          if (row.id === id && row.rowType !== TableRowTypes.FOOTER) {
            return { ...row, [field]: html };
          } else if (row.id === id && row.rowType === TableRowTypes.FOOTER) {
            const convertedRowToFooterData = getSanitizedDataForTableFooter(row, tableData.columns, html);
            return { ...convertedRowToFooterData, rowType: TableRowTypes.FOOTER };
          } else {
            return { ...row };
          }
        });
        await blockContentChangedHandler(blockId, sectionId, { ...tableData, rows: updatedRows });
      }
    },
  });
  useEffect(() => {
    if (editor && hasFocus) {
      setActiveEditor(editor);
    }
  }, [editor, setActiveEditor, hasFocus]);
  return <EditorContent editor={editor} />;
};

export function useApplyRulesToColumn() {
  const applyCustomRulesToColumns = (columns: TableColumnType[]): TableColumnType[] => {
    let updatedColumns: TableColumnType[] = [...columns];
    updatedColumns = setCustomInputToDefaultColumn(updatedColumns);
    updatedColumns = setColumnsColSpanRuleForFooter(updatedColumns);

    return updatedColumns;
  };

  const setCustomInputToDefaultColumn = (columns: TableColumnType[]): TableColumnType[] => {
    for (let i = 0; i < columns.length; i += 1) {
      if (!columns[i].type || columns[i].type === 'string') {
        columns[i] = {
          ...columns[i],
          renderCell: (params) => {
            return <DataGridCustomAutoGrowingInput {...params} />;
          },
          renderEditCell: (params) => <RenderEditCell {...params} />,
        };
      }
    }
    return columns;
  };

  const setColumnsColSpanRuleForFooter = (columns: TableColumnType[]): TableColumnType[] => {
    const firstColumnAfterId = 1;

    columns[firstColumnAfterId] = {
      ...columns[firstColumnAfterId],
      colSpan: (_value, row): number => {
        let colSpan = 1;
        if (row.rowType === TableRowTypes.FOOTER) {
          // -1 because the first row is the id, which is not visible
          colSpan = columns.length - 1;
        }
        return colSpan;
      },
    };

    return columns;
  };

  return { applyCustomRulesToColumns };
}
