import { SvgIcon } from '@mui/material';

const Link = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.9368 5.07013C21.5188 4.65061 21.022 4.31774 20.475 4.09062C19.928 3.8635 19.3416 3.74658 18.7493 3.74658C18.1571 3.74658 17.5706 3.8635 17.0236 4.09062C16.4767 4.31774 15.9799 4.65061 15.5618 5.07013L16.6268 6.13513C16.9061 5.85591 17.2375 5.63442 17.6024 5.4833C17.9672 5.33219 18.3582 5.25441 18.7531 5.25441C19.148 5.25441 19.539 5.33219 19.9038 5.4833C20.2686 5.63442 20.6001 5.85591 20.8793 6.13513C21.1586 6.41435 21.38 6.74584 21.5312 7.11066C21.6823 7.47548 21.7601 7.8665 21.7601 8.26138C21.7601 8.65626 21.6823 9.04728 21.5312 9.4121C21.38 9.77692 21.1586 10.1084 20.8793 10.3876L14.8793 16.3876C14.3164 16.9515 13.5525 17.2687 12.7557 17.2695C11.9589 17.2702 11.1945 16.9543 10.6306 16.3914C10.0667 15.8285 9.74946 15.0646 9.74876 14.2678C9.74806 13.471 10.0639 12.7065 10.6268 12.1426L11.6843 11.0776L10.6268 10.0126L9.56183 11.0776C9.14231 11.4957 8.80944 11.9925 8.58232 12.5394C8.3552 13.0864 8.23828 13.6729 8.23828 14.2651C8.23828 14.8574 8.3552 15.4438 8.58232 15.9908C8.80944 16.5378 9.14231 17.0346 9.56183 17.4526C10.4104 18.2904 11.5569 18.757 12.7493 18.7501C13.3439 18.7526 13.933 18.6372 14.4827 18.4106C15.0324 18.184 15.5317 17.8508 15.9518 17.4301L21.9518 11.4301C22.7925 10.5844 23.2632 9.43953 23.2604 8.24703C23.2576 7.05452 22.7815 5.91189 21.9368 5.07013Z"
          fill="currentColor"
        />
        <path
          d="M3.14333 18.6149C2.86328 18.3361 2.64105 18.0048 2.48941 17.6399C2.33777 17.275 2.25971 16.8838 2.25971 16.4886C2.25971 16.0935 2.33777 15.7022 2.48941 15.3373C2.64105 14.9725 2.86328 14.6411 3.14333 14.3624L9.14333 8.36237C9.42209 8.08231 9.75342 7.86008 10.1183 7.70844C10.4832 7.55681 10.8744 7.47875 11.2696 7.47875C11.6647 7.47875 12.056 7.55681 12.4209 7.70844C12.7857 7.86008 13.1171 8.08231 13.3958 8.36237C13.6741 8.64332 13.8932 8.97727 14.04 9.34443C14.1869 9.71159 14.2586 10.1045 14.2508 10.4999C14.2531 10.8965 14.1767 11.2896 14.0261 11.6565C13.8755 12.0234 13.6536 12.3568 13.3733 12.6374L11.7833 14.2499L12.8483 15.3149L14.4383 13.7249C15.2847 12.8785 15.7602 11.7306 15.7602 10.5336C15.7602 9.33667 15.2847 8.18874 14.4383 7.34237C13.592 6.49599 12.444 6.02051 11.2471 6.02051C10.0501 6.02051 8.9022 6.49599 8.05583 7.34237L2.05583 13.3424C1.63518 13.7606 1.30136 14.2578 1.07357 14.8055C0.845783 15.3532 0.728516 15.9405 0.728516 16.5336C0.728516 17.1268 0.845783 17.7141 1.07357 18.2618C1.30136 18.8094 1.63518 19.3067 2.05583 19.7249C2.90995 20.5562 4.059 21.0148 5.25083 20.9999C6.45316 21.001 7.60712 20.5265 8.46083 19.6799L7.39583 18.6149C7.11708 18.8949 6.78574 19.1172 6.42086 19.2688C6.05597 19.4204 5.66472 19.4985 5.26958 19.4985C4.87444 19.4985 4.48319 19.4204 4.11831 19.2688C3.75342 19.1172 3.42209 18.8949 3.14333 18.6149Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default Link;
