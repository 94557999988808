import { Box } from '@mui/material';
import { EditorFroalaToolbar } from './EditorFroalaToolbar';
import { EditorUndoRedoToolbar } from './EditorUndoRedoToolbar';
import TiptapMenubar from './TiptapMenuBar';

export function EditorToolbar() {
  return (
    <Box
      id="editor-toolbar-container"
      className="editor-toolbar-container"
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'normal',
        width: '100%',
        height: '50px',
        borderTop: '1px solid #f0f0f0',
        borderRadius: '5px',
        borderBottom: '1px solid #f0f0f0',
      }}
    >
      <EditorUndoRedoToolbar />
      <TiptapMenubar />
      <EditorFroalaToolbar />
    </Box>
  );
}
