import { SvgIcon } from '@mui/material';

const Mention = () => {
  return (
    <SvgIcon>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.2263 6.24984C16.6033 1.70784 10.7943 0.151841 6.25233 2.77484C1.70933 5.39684 0.153329 11.2058 2.77533 15.7488C5.39833 20.2908 11.2063 21.8478 15.7493 19.2258C15.9215 19.128 16.1254 19.1021 16.3165 19.154C16.5077 19.2059 16.6705 19.3313 16.7695 19.5028C16.8686 19.6743 16.8957 19.8781 16.8451 20.0695C16.7945 20.261 16.6702 20.4247 16.4993 20.5248C11.2393 23.5618 4.51233 21.7598 1.47533 16.4988C-1.56167 11.2398 0.240329 4.51184 5.50133 1.47484C10.7603 -1.56216 17.4883 0.239841 20.5253 5.50084C21.3787 6.9746 21.8769 8.62697 21.9803 10.3268C21.9933 10.3828 22.0003 10.4398 22.0003 10.4998V12.7498C22.0005 13.5369 21.7354 14.3011 21.2478 14.919C20.7602 15.5368 20.0786 15.9723 19.313 16.155C18.5474 16.3378 17.7426 16.2572 17.0285 15.9262C16.3144 15.5952 15.7327 15.0331 15.3773 14.3308C14.5966 15.3568 13.4803 16.0758 12.2233 16.3624C10.9664 16.6491 9.64866 16.485 8.50035 15.899C7.35204 15.3129 6.44609 14.3421 5.94073 13.1561C5.43537 11.97 5.3627 10.6441 5.73543 9.40998C6.10816 8.17582 6.90259 7.11181 7.97999 6.40378C9.05738 5.69574 10.3493 5.38869 11.63 5.53623C12.9107 5.68378 14.099 6.27654 14.9871 7.21101C15.8753 8.14548 16.407 9.36226 16.4893 10.6488C16.4965 10.6915 16.5001 10.7346 16.5003 10.7778V12.7498C16.5003 13.2803 16.711 13.789 17.0861 14.1641C17.4612 14.5391 17.9699 14.7498 18.5003 14.7498C19.0308 14.7498 19.5395 14.5391 19.9145 14.1641C20.2896 13.789 20.5003 13.2803 20.5003 12.7498V10.9838C20.499 9.32162 20.0596 7.68811 19.2263 6.24984ZM15.0003 10.9998C15.0003 9.93897 14.5789 8.92156 13.8288 8.17141C13.0786 7.42127 12.0612 6.99984 11.0003 6.99984C9.93946 6.99984 8.92205 7.42127 8.1719 8.17141C7.42176 8.92156 7.00033 9.93897 7.00033 10.9998C7.00033 12.0607 7.42176 13.0781 8.1719 13.8283C8.92205 14.5784 9.93946 14.9998 11.0003 14.9998C12.0612 14.9998 13.0786 14.5784 13.8288 13.8283C14.5789 13.0781 15.0003 12.0607 15.0003 10.9998Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default Mention;
