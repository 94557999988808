/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { SuggestionProps } from '@tiptap/suggestion';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ITextEditorOption } from '../../types.d';

// Define styles using Emotion's css function
const listStyle = (theme: Theme) =>
  css({
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    '& ul': { padding: 0 },
  });

const itemStyle = (theme: Theme) =>
  css({
    '& .MuiTypography-root': {
      color: theme.palette.grey[800],
    },
  });

type Props = {
  command: (value: { id: ITextEditorOption }) => void;
} & SuggestionProps<ITextEditorOption>;

// eslint-disable-next-line react/display-name
const Mentions = forwardRef<any, Props>(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = items[index];

    if (item) {
      command({ id: item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }): boolean => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <List css={listStyle}>
      {items.length ? (
        items.map((item: ITextEditorOption, index: number) => (
          <ListItem disablePadding key={index}>
            <ListItemButton onClick={() => selectItem(index)} className={`item ${index === selectedIndex ? 'is-selected' : ''}`}>
              <ListItemText primary={item.label} css={itemStyle} />
            </ListItemButton>
          </ListItem>
        ))
      ) : (
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText primary="No results" />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
});

export default Mentions;
